import React from 'react';
import styled from 'styled-components';
import layout from '../../services/layout';
import AdLabel from './AdLabel';

const GridOrPortrait = (styling) => `
  &.grid-view {
    ${styling}
  }
  @media (max-width: ${layout.search.listViewLandscapeMaxWidth}) {
    && {
      ${styling}
    }
  }
`;

const OuterContainer = styled.div`
  display: flex;

  ${GridOrPortrait(`
    margin: 0;
    position: absolute;
    top: -15px;
    left: 0;
  `)}
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
`;

function AdPills({ isSold, isUrgent, isPremium, auction, layoutClass }) {
  return (
    <OuterContainer className={layoutClass}>
      <InnerContainer className={layoutClass}>
        <AdLabel
          isSold={isSold}
          isUrgent={isUrgent}
          isPremium={isPremium}
          auction={auction}
          layoutClass={layoutClass}
        />
      </InnerContainer>
    </OuterContainer>
  );
}

export default AdPills;
