import axios from 'axios';
import config from './config';
import authenticationService from './AuthenticationService';

const client = axios.create({
  baseURL: config.get('SEARCH_API_URL')
});

export default {
  getMemberProfile: async memberId => {
    let response = await client.get(`/member/${memberId}/profile`, await authenticationService.getRequestOptions(null, true));
    return response.data;
  },
  updateMemberPaymentMethod: async (paymentMethod) => {
    let response = await client.post('/member/payment/method', paymentMethod, await authenticationService.getRequestOptions(null, true));
    return response.data;
  },
  updateMemberPhone: async (memberId, phoneDetails) => {
    let response = await client.post(`/member/${memberId}/phone`, phoneDetails, await authenticationService.getRequestOptions(null, true));
    return response.data;
  },
  updateMemberName: async (memberId, name) => {
    let response = await client.post(`/member/${memberId}/name`, { name }, await authenticationService.getRequestOptions(null, true));
    return response.data;
  },
  findPhoneNumber: async (userId, email) => {
    let response = await client.post(`/member/phone/find`, { userId, email }, await authenticationService.getRequestOptions(null, true));
    return response.data;
  }
}