import React, { useEffect, useState } from 'react';
import MemberProfileUpdateService from '../../services/MemberProfileUpdateService';
import { Button, Form, Input, Message, Icon } from 'semantic-ui-react';
import ValidatedFormField from './ValidatedFormField';
import layout from '../../services/layout';
import styled from 'styled-components';

const FormActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
`;

function MemberPhoneForm({ memberId, defaultPhoneDetails, onSuccess }) {
  const [phoneDetails, setPhoneDetails] = useState({ raw: '' });
  const [isLoading, setIsLoading] = useState(!defaultPhoneDetails);
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    if (!defaultPhoneDetails) {
      (async () => {
        setIsLoading(true);
        const memberProfile = await MemberProfileUpdateService.getMemberProfile(
          memberId
        );
        if (memberProfile.phoneDetails) {
          setPhoneDetails(memberProfile.phoneDetails);
        }
        setIsLoading(false);
      })();
    }
  }, [memberId]);

  const handleSavePhone = async () => {
    try {
      if (phoneDetails.raw?.length < 6) {
        setErrorMessage({
          title: 'Phone number is required',
          success: false,
        });
        return;
      }

      setIsLoading(true);
      setErrorMessage(null);
      await MemberProfileUpdateService.updateMemberPhone(
        memberId,
        phoneDetails
      );
      if (onSuccess) {
        onSuccess();
      }
    } catch (ex) {
      setErrorMessage({
        title: 'Sorry, something went wrong',
        body: 'We will be looking into this.  In the meantime, please try again.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form inverted onSubmit={handleSavePhone}>
      <Form.Group widths="equal">
        <ValidatedFormField
          id="member-phone-form"
          autoComplete="phone no"
          name="phone"
          label="Phone Number"
          placeholder="Phone Number"
          icon="phone"
          iconPosition="left"
          control={Input}
          value={phoneDetails.raw}
          errorMessage={phoneDetails.validationError}
          onChange={(_, { value: phone }) => setPhoneDetails({ raw: phone })}
          loading={isLoading}
          disabled={isLoading}
        />
      </Form.Group>
      <FormActions>
        <Button
          loading={isLoading}
          disabled={isLoading}
          primary
          style={{ backgroundColor: layout.colors.primary, marginLeft: 'auto' }}
        >
          Update Phone
        </Button>
      </FormActions>
      {errorMessage && (
        <Message
          negative
          icon="exclamation"
          header={errorMessage.title}
          content={errorMessage.body}
        />
      )}
    </Form>
  );
}

export default MemberPhoneForm;
