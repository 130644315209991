import React from 'react';
import ReactCountdown from 'react-countdown';
import styled from 'styled-components';

const renderer = ({ days, hours, minutes, seconds }) => {
  const values = [hours + (days * 24), minutes, seconds].map(x => x.toString().padStart(2, '0'));
  return <span>{values.join(':')}</span>
};

const FlipContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const FlipValue = styled.div`
  background: black;
  padding: 10px 0;
  width: 50px;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  font-size: 24px;
`

const FlipSeparator = styled.div`
  color: #ccc;
  font-size: 24px;
`

const flipRenderer = ({ days, hours, minutes, seconds }) => {
  const values = [hours + (days * 24), minutes, seconds].map(x => x.toString().padStart(2, '0'));
  return <FlipContainer>{values.map((value, index) => <>{index > 0 && <FlipSeparator>:</FlipSeparator>}<FlipValue key={index}>{value}</FlipValue></>)}</FlipContainer>
};

function Countdown({ date, completed, flip }) {
  return typeof window === 'undefined'
    ? <span>{completed}</span>
    : (date
      ? <ReactCountdown
        date={date}
        renderer={flip ? flipRenderer : renderer}
      />
      : <span>{completed}</span>
    )
}

export default Countdown;