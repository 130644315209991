import React from 'react'
import styled from 'styled-components'

const PageBody = styled.div`
  background-color: #000;
`
const PageContent = styled.div`
  color: white;
`

function Page({ children }) {
  return (
    <PageBody>
      <PageContent>
        {children}
      </PageContent>
    </PageBody>
  )
}

export default Page
