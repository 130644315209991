import axios from 'axios';
import config from './config';
import authenticationService from './AuthenticationService';

const client = axios.create({
  baseURL: config.get('SEARCH_API_URL')
});

let auctionRefreshInProgress = false;
let auctionRefreshInterval = null;
let auctionRefreshIntervalId = null;
const auctionSubscriptions = {};

const loadAuctions = async (subscriptions) => {
  const response = await client.post('/inventory/auctions', {
    subscriptions: subscriptions,
    timezoneName: getTimezoneName()
  }, await authenticationService.getRequestOptions(null, true));

  return response.data;
}

const getTimezoneName = () => {
  let timezoneName = null;
  try {
    timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (!timezoneName) {
      throw 'client timezone is empty';
    }
  }
  catch (ex) {
    console.error('failed to get client timezone', ex);
  }
  return timezoneName;
}

const refreshAuctions = async () => {
  if (auctionRefreshInProgress) {
    console.warn('auction refresh is already in progress, might be running too slowly?');
    return;
  }

  try {
    auctionRefreshInProgress = true;
    const subscriptions = Object.keys(auctionSubscriptions).map(adId => ({
      adId: adId,
      placements: Object.keys(auctionSubscriptions[adId].refreshCallbacks)
    }));
    if (subscriptions.length > 0) {
      const auctions = await loadAuctions(subscriptions);
      auctions.forEach(auction => {
        const subscription = auctionSubscriptions[auction.adId];
        subscription.auction = auction;
        const refreshCallbacks = Object.values(subscription.refreshCallbacks);
        refreshCallbacks.forEach(refreshCallback => refreshCallback(auction));
      });
      maintainRefreshInterval();
    }
  }
  catch (ex) {
    console.error('failed to refresh auctions', ex);
  }
  finally {
    auctionRefreshInProgress = false;
  }
}

const maintainRefreshInterval = () => {
  const subscribedAuctionIds = Object.keys(auctionSubscriptions);
  if (subscribedAuctionIds.length > 0) {
    const refreshIntervals = Object.values(auctionSubscriptions).map(subscription => subscription.auction?.ending.refreshInterval || 3600000);
    const refreshInterval = Math.min(...refreshIntervals);
    if (refreshInterval !== auctionRefreshInterval) {
      clearInterval(auctionRefreshIntervalId);
      if (refreshInterval > 0) {
        auctionRefreshIntervalId = setInterval(refreshAuctions, refreshInterval);
        auctionRefreshInterval = refreshInterval;
      }
    }
  }
  else {
    if (auctionRefreshIntervalId) {
      clearInterval(auctionRefreshIntervalId);
      auctionRefreshIntervalId = null;
      auctionRefreshInterval = null;
    }
  }
}

const handleBid = async (auction, bid, validateOnly) => {
  const response = await client.post('/inventory/auctions/bid', {
    userId: bid.userId,
    adId: auction.adId,
    amount: bid.amount,
    isMaxiumumBid: bid.isMaxiumumBid,
    timezoneName: getTimezoneName(),
    validateOnly: validateOnly
  }, await authenticationService.getRequestOptions(null, true));
  return response.data;
}

const acceptExclusiveOffer = async (adId, userId) => {
  const response = await client.post('/inventory/auctions/accept-exclusive-offer', {
    adId, userId
  }, await authenticationService.getRequestOptions(null, true));
  return response.data;
}

const self = {
  handleBid,
  refreshAuctions,
  subscribeToAuction: async (adId, placement, refreshCallback, loadImmediately) => {
    let subscription = auctionSubscriptions[adId];
    if (!subscription) {
      subscription = auctionSubscriptions[adId] = {
        refreshCallbacks: {}
      };
    }
    subscription.refreshCallbacks[placement] = refreshCallback;

    if (loadImmediately) {
      const auction = (await loadAuctions([{ adId: adId, placements: [placement] }]))[0];
      subscription.auction = auction;
      refreshCallback(auction);
    }

    maintainRefreshInterval();
  },
  unsubscribeFromAuction: async (adId, placement) => {
    const subscription = auctionSubscriptions[adId];
    if (subscription) {
      delete subscription.refreshCallbacks[placement];
      if (Object.keys(subscription.refreshCallbacks).length === 0) {
        delete auctionSubscriptions[adId];
      }
    }
    maintainRefreshInterval();
  },
  loadBidsPage: async (id, page) => {
    const response = await client.get(`/inventory/auction/${id}/bids/${page}`, await authenticationService.getRequestOptions(null, true));

    return response.data;
  },
  acceptExclusiveOffer
}

export default self;