import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledPopup = styled(Popup)`
  &, &:before {
    background: #333 !important;
  }
`

function InvertedPopup(props) {
  return <StyledPopup inverted {...props} />
}

export default InvertedPopup;