import React from "react"
import styled from "styled-components"
import { Form } from "semantic-ui-react"

const StyledFormField = styled(Form.Field)`
  &.error > label {
    color: #ed1f24 !important;
  }
  .ui.pointing.above.prompt.label {
    text-align: center;
    border: none !important;
    display: block;
    background-color: #ed1f24 !important;
    color: white !important;

    &::before {
      border: none !important;
    }
  }
`

const ValidatedFormField = ({ errorMessage, ...rest }) => {
  return (
    <StyledFormField
      error={errorMessage && {
        content: errorMessage,
        pointing: 'above'
      }}
      {...rest} />
  )
}

export default ValidatedFormField;