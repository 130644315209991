import React, { useState } from "react";
import memberWatchlistService from "../../services/MemberWatchlistService";
import {
  Button,
  Message,
  Modal,
  Header,
  Icon,
  Loader,
} from "semantic-ui-react";
import { useRouter } from "next/router";
import { useAuth } from "../../hooks/use-auth";
import styled from "styled-components";
import AnalyticEventService from "../../services/AnalyticEventService";
import Link from "next/link";
import layout from "../../services/layout";
import queryStringService from "../../services/queryStringService";
import InvertedModal from "./InvertedModal";
import MemberPhoneForm from "./MemberPhoneForm";
import MemberProfileUpdateService from "../../services/MemberProfileUpdateService";

const Container = styled.div`
  position: relative;
`;

const WatchButton = styled.span`
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  &.is-loading {
    color: #ccc;
  }

  &.mobile-icon-only {
    vertical-align: middle;
    line-height: initial;
    span {
      line-height: 20px;
      vertical-align: top;
    }
    .loader {
      width: 24px;
      height: 19px;
      margin-right: 0 !important;
    }

    @media (max-width: ${layout.global.mobileMax}) {
      .icon {
        font-size: 20px;
      }
      span {
        display: none;
      }
    }
  }

  && {
    &.icon-only {
      .icon {
        margin: 0;
        font-size: 20px;
        line-height: 20px;
      }
      span {
        display: none;
      }
    }
    .loader,
    .icon {
      margin-right: 5px;
      display: inline-block !important;
    }
  }
`;

function WatchAdWidget(props) {
  const auth = useAuth();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [modalOpen, setModalOpenState] = useState(false);
  const [phoneNumberModalOpen, setPhoneNumberModalOpen] = useState(false);

  const adIsWatched = auth.memberWatchlist.some((id) => id == props.adId);

  const onWatchClick = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    try {
      if (!auth.user.userId) {
        router.push(
          "/authenticate?returnUrl=" + encodeURIComponent(router.asPath)
        );
        CreateAnalyticEvent("AUTH_REDIRECT", "AD_SAVE");
      } else {
        if (adIsWatched) {
          //Delete member ad
          await memberWatchlistService.unwatchAd(auth.user.userId, props.adId);
          auth.updateMemberWatchlist(false, props.adId);
        } else {
          let result = await memberWatchlistService.watchAd(
            auth.user.userId,
            props.adId
          );

          if (!result.success) {
            setErrorMessage({
              title: "Error",
              body: result.message,
            });
            setModalOpenState(true);
          } else {
            auth.updateMemberWatchlist(true, props.adId);

            if (props.auction) {
              const memberProfile =
                await MemberProfileUpdateService.getMemberProfile(
                  auth.user.userId
                );

              let mustCapturePhoneNumber = !memberProfile?.phoneDetails?.raw;

              if (mustCapturePhoneNumber) {
                const phoneNumber =
                  await MemberProfileUpdateService.findPhoneNumber(
                    auth.user.userId,
                    auth.user.email
                  );

                if (phoneNumber?.raw) {
                  await MemberProfileUpdateService.updateMemberPhone(
                    auth.user.userId,
                    { raw: phoneNumber.raw }
                  );
                  mustCapturePhoneNumber = true;
                }
              }

              if (mustCapturePhoneNumber) {
                setPhoneNumberModalOpen(true);
              }
            }
          }
        }
        const eventType = adIsWatched ? "AD_UNSAVE" : "AD_SAVE";
        CreateAnalyticEvent(eventType);
      }
    } catch (ex) {
      console.error("failed to watch ad", ex);
      setErrorMessage({
        title: "Error",
        body: "Sorry, we couldn't watch this ad currently.",
      });
      setModalOpenState(true);
    } finally {
      setIsLoading(false);
    }
  };

  const CreateAnalyticEvent = async (eventType, redirectSource) => {
    await AnalyticEventService.createAnalyticEvent({
      eventType: eventType,
      ads: [
        {
          id: props.adId,
        },
      ],
      metadata: {
        source: props.source,
        redirectSource: redirectSource,
        adSource: queryStringService.get("source"),
      },
    });
  };

  return (
    <Container>
      <WatchButton
        className={
          (isLoading ? "is-loading" : "") +
          (props.mobileIconOnly ? " mobile-icon-only" : "") +
          (props.iconOnly ? " icon-only" : "")
        }
        onClick={onWatchClick}
        aria-label="Watch Ad"
      >
        {isLoading ? (
          <Loader active size="tiny" inline />
        ) : (
          <Icon name={adIsWatched ? "star" : "star outline"} />
        )}
        <span>{adIsWatched ? "Watching" : "Watch"}</span>
      </WatchButton>
      {errorMessage && (
        <Modal
          open={modalOpen}
          onClose={() => setModalOpenState(false)}
          onOpen={() => setModalOpenState(true)}
        >
          <Header icon="remove" content={errorMessage.title} />
          <Modal.Content>
            <Message negative>
              <p>{errorMessage.body}</p>
            </Message>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setModalOpenState(false)}>Close</Button>
            <Link href="/member/watchlist">
              <Button primary>
                Go to Watchlist
                <Icon name="arrow right" />
              </Button>
            </Link>
          </Modal.Actions>
        </Modal>
      )}
      {phoneNumberModalOpen && (
        <InvertedModal
          header="Please leave a phone number"
          content={
            <>
              <p>For an SMS reminder about this auction ending</p>
              <MemberPhoneForm
                memberId={auth.user.userId}
                defaultPhoneDetails={{}}
                onSuccess={() => setPhoneNumberModalOpen(false)}
              />
            </>
          }
          isOpen={phoneNumberModalOpen}
          closeOnBack
          onClose={() => setPhoneNumberModalOpen(false)}
          size="tiny"
        />
      )}
    </Container>
  );
}
export default WatchAdWidget;
