import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Modal, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import layout from '../../services/layout';

const StyledModal = styled(Modal)`
  &&&,
  > .header,
  > .content,
  > .actions {
    background: #1b1b1b !important;
    color: white !important;
  }
  &.ui.modal > .header {
    background: #2b2b2b !important;
  }
`;

const HeaderContainer = styled(Modal.Header)`
  &&&& {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon {
      cursor: pointer;
      margin: 0;
      text-align: right;
      align-self: start;
    }

    @media (max-width: ${layout.global.smallMax}) {
      padding-right: 15px !important;
    }
  }
`;

function InvertedModal({
  trigger,
  header,
  content,
  onOpen,
  closeOnBack,
  size,
  isOpen,
  onClose
}) {
  const [isOpenState, setIsOpenState] = useState(false);
  const router = useRouter();
  
  isOpen = isOpen || isOpenState;

  const handleSetIsOpen = (value) => {
    setIsOpenState(value);
    if (!value && onClose) {
      onClose();
    }
    if (value && onOpen) {
      onOpen();
    }
  }

  useEffect(() => {
    if (isOpen && closeOnBack) {
      router.beforePopState(({ as }) => {
        const currentPath = router.asPath;
        if (as !== currentPath) {
          handleSetIsOpen(false);
          window.history.pushState(null, '', currentPath);
          return false;
        }
        return true;
      });
    }
    return () => {
      router.beforePopState(() => true);
    };
  }, [router, isOpen, closeOnBack]);

  return (
    <StyledModal
      size={size || 'large'}
      onClose={() => handleSetIsOpen(false)}
      onOpen={() => handleSetIsOpen(true)}
      open={isOpen}
      trigger={trigger}
    >
      {header && (
        <HeaderContainer>
          <div>{header}</div>
          <Icon name="close" onClick={() => handleSetIsOpen(false)} size="large" />
        </HeaderContainer>
      )}
      <Modal.Content>{content}</Modal.Content>
    </StyledModal>
  );
}

export default InvertedModal;
