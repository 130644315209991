import React, { useState } from 'react';
import styled from 'styled-components';
import { FacebookShareButton, FacebookIcon, EmailShareButton, EmailIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon } from 'react-share';
import config from '../../services/config';
import { Button, Icon, List, Popup } from 'semantic-ui-react';
import layout from '../../services/layout';
import copy from 'copy-to-clipboard';
import AnalyticEventService from '../../services/AnalyticEventService';
import queryStringService from '../../services/queryStringService';

const shareIconSize = 32;

const SocialSharing = styled.div`
  margin-left: auto;

  .react-share__ShareButton {
    margin-left: 5px;
      &:focus {
        outline: none;
      }
  }
`

const RoundButton = styled(Button)`
  border: 2px solid ${layout.buttons.inactiveOutline} !important;
  height: ${shareIconSize}px;
  width: ${shareIconSize}px;
  border-radius: ${shareIconSize}px !important;
  padding: 0 !important;
  vertical-align: top !important;

  &&&&&&&& {
    margin-left: 5px !important;
    margin-right: 0 !important;
    > i {
      font-size: 16px !important;
      margin: initial !important;
    }
  }
`

const ShareButtonLabel = styled.span`
  vertical-align: top;
  line-height: ${shareIconSize}px;
  display: inline-block;
  margin-left: 10px;
`

const CustomShareButton = styled.div`
  cursor: pointer;
`

const ShareList = ({ adId, adClassicClassifiedId, adTitle, adShortDescription, source }) => {
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const shareUrl = config.get('WEB_URL') + adClassicClassifiedId;

  const PermalinkButtonClicked = () => {
    const wasCopied = copy(shareUrl);
    setCopiedToClipboard(wasCopied);
    createAnalyticEvent('AD_SHARE_PERMALINK');
  }

  const NativeShareClicked = () => {
    navigator.share({
      url: shareUrl,
      text: adTitle,
      title: adTitle
    });
    createAnalyticEvent('AD_SHARE_NATIVE');
  }

  const createAnalyticEvent = (eventType) => {
    AnalyticEventService.createAnalyticEvent({
      eventType: eventType,
      ads: [
        {
          id: adId
        }
      ],
      metadata: {
        source: source + '_LIST',
        adSource: queryStringService.get('source')
      }
    });
  }

  return (
    <SocialSharing>
      <List relaxed>
        <List.Item>
          <FacebookShareButton
            url={shareUrl}
            hashtag='my105'
            beforeOnClick={() => createAnalyticEvent('AD_SHARE_FACEBOOK')}>
            <FacebookIcon round='true' size={shareIconSize} />
            <ShareButtonLabel>Facebook</ShareButtonLabel>
          </FacebookShareButton>
        </List.Item>
        <List.Item>
          <WhatsappShareButton
            url={shareUrl}
            title={adTitle}
            beforeOnClick={() => createAnalyticEvent('AD_SHARE_WHATSAPP')}>
            <WhatsappIcon round='true' size={shareIconSize} />
            <ShareButtonLabel>WhatsApp</ShareButtonLabel>
          </WhatsappShareButton>
        </List.Item>
        <List.Item>
          <TwitterShareButton
            title={adTitle}
            url={shareUrl}
            hashtags={['my105']}
            beforeOnClick={() => createAnalyticEvent('AD_SHARE_TWITTER')}>
            <TwitterIcon round='true' size={shareIconSize} />
            <ShareButtonLabel>Twitter</ShareButtonLabel>
          </TwitterShareButton>
        </List.Item>
        <List.Item>
          <EmailShareButton
            url={shareUrl}
            body={adShortDescription}
            subject={adTitle}
            beforeOnClick={() => createAnalyticEvent('AD_SHARE_EMAIL')}>
            <EmailIcon round='true' size={shareIconSize} />
            <ShareButtonLabel>Email</ShareButtonLabel>
          </EmailShareButton>
        </List.Item>
        <List.Item>
          <Popup content={<>{copiedToClipboard && <div><Icon name='check' />Copied to clipboard</div>}<a href={shareUrl}>{shareUrl}</a></>} on='click' trigger={
            <CustomShareButton onClick={PermalinkButtonClicked}>
              <RoundButton icon round='true'>
                <Icon name='linkify' />
              </RoundButton>
              <ShareButtonLabel>Copy Link</ShareButtonLabel>
            </CustomShareButton>
          } />
        </List.Item>
        {navigator.share && <List.Item>
          <CustomShareButton onClick={NativeShareClicked}>
            <RoundButton icon round='true'>
              <Icon name='share alternate' />
            </RoundButton>
            <ShareButtonLabel>Other Share Options</ShareButtonLabel>
          </CustomShareButton>
        </List.Item>}
      </List>
    </SocialSharing>
  )
}
export default ShareList
