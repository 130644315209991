export default {
  get: (key) => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(key) || 'UNKNOWN';
    }
    catch (ex) {
      console.log('failed to read query string param', ex);
      return 'FAILED_TO_READ';
    }
  }
}