import React from 'react';
import { Divider, Icon, Dropdown, DropdownMenu, DropdownItem } from 'semantic-ui-react';
import { useAuth } from '../../hooks/use-auth';
import config from '../../services/config';

function ListingAdminDropdown({ ad }) {
  const { user } = useAuth();

  return user.isAdmin
    ? <Dropdown direction='left'>
      <DropdownMenu>
        <DropdownItem>
          <a href={`${config.get('SELL_SITE_URL')}update/${ad.id}/details`} target='_blank' rel="noopener noreferrer">
            <Icon name='edit' /> Edit
          </a>
        </DropdownItem>
        <DropdownItem>
          <a href={`${config.get('SELL_SITE_URL')}admin?query=${ad.classicClassifiedId}`} target='_blank' rel="noopener noreferrer">
            <Icon name='angle right' /> Show
          </a>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
    : <></>
}

export default ListingAdminDropdown;