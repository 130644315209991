import React from 'react';
import styled from 'styled-components';
import layout from '../../services/layout';

const GridOrPortrait = (styling) => `
  &.grid-view {
    ${styling}
  }
  @media (max-width: ${layout.search.listViewLandscapeMaxWidth}) {
    && {
      ${styling}
    }
  }
`;

const Label = styled.span`
  font-weight: bold;
  background: #2e2e2e;
  white-space: nowrap;
  padding: 5px 10px;
  border-radius: 50px;

  ${GridOrPortrait(`
    border-radius: 0 50px 50px 0;
  `)}

  &:last-child {
    margin-right: 10px;
  }

  i {
    margin: 0;
  }

  &.ad-details {
    border-radius: 50px;
  }
`;

function AdLabel({ isSold, isUrgent, isPremium, auction, layoutClass }) {
  let label;

  if (isSold) {
    label = {
      text: 'Sold',
      style: { background: layout.colors.primary },
    };
  } else if (isUrgent) {
    label = {
      text: 'Urgent',
      style: { background: layout.colors.green },
    };
  } else if (auction?.label?.text) {
    label = auction.label;
  } else if (isPremium) {
    label = {
      text: 'Premium',
      style: { background: layout.colors.premium, color: 'black' },
    };
  }

  return (
    label && (
      <Label className={layoutClass} style={label.style}>
        {label.text}
      </Label>
    )
  );
}

export default AdLabel;
