import React from 'react'
import { Icon } from 'semantic-ui-react'
import styled from 'styled-components'

const PhotoCountContainer = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
`

const CountContainer = styled.div`
  background-color: rgba(0,0,0,0.7);
  padding: 1px 8px;
  border-radius: 50px;
  font-size: 12px;
`

const VideoCountContainer = styled.span`
  margin-left: 8px;
  padding-left: 8px;
  border-left: 1px solid #a4a4a4;
`

function GalleryCountPill({ gallery }) {
  return <PhotoCountContainer>
    <CountContainer>
      <Icon name='camera' /> {gallery.imageCount}
      {gallery.videoCount > 0 && <VideoCountContainer><Icon name='video' /> {gallery.videoCount}</VideoCountContainer>}
    </CountContainer>
  </PhotoCountContainer>
}

export default GalleryCountPill;