import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon, Modal } from 'semantic-ui-react';
import ShareList from './ShareList';
import AnalyticEventService from '../../services/AnalyticEventService';
import layout from '../../services/layout';
import queryStringService from '../../services/queryStringService';

const Container = styled.div`
  position: relative;
`

const ShareLabel = styled.span`
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;

  &.mobile-icon-only {
    vertical-align: middle;
    line-height: initial;
    span {
      line-height: 20px;
      vertical-align: top;
    }
    
    @media (max-width: ${layout.global.mobileMax}) {
      .icon {
        font-size: 20px;
      }
      span {
        display: none;
      }
    }
  }

  && {
    &.icon-only {
      .icon {
        margin: 0;
        font-size: 20px;
        line-height: 20px;
      }
      span {
        display: none;
      }
    }
    .icon {
      margin-right: 5px;
      display: inline-block !important;
    }
  }
`

const ShareWidget = ({ adId, adClassicClassifiedId, adTitle, adShortDescription, source, mobileIconOnly, iconOnly }) => {
  const [open, setOpen] = useState(false);

  const createAnalyticEvent = (eventType) => {
    AnalyticEventService.createAnalyticEvent({
      eventType: eventType,
      ads: [
        {
          id: adId
        }
      ],
      metadata: {
        source: source,
        adSource: queryStringService.get('source')
      }
    });
  }

  return <Container>
    <Modal
      basic
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => {
        setOpen(true);
        createAnalyticEvent('AD_SHARE_OPEN');
      }}
      open={open}
      trigger={
        <ShareLabel className={(mobileIconOnly ? 'mobile-icon-only' : '') + (iconOnly ? ' icon-only' : '')}>
          <Icon name='share alternate' />
          <span>Share</span>
        </ShareLabel>
      }
    >
      <Modal.Header>Share {adTitle}</Modal.Header>
      <Modal.Content>
        <ShareList adId={adId} adClassicClassifiedId={adClassicClassifiedId} adTitle={adTitle} adShortDescription={adShortDescription} source={source} />
      </Modal.Content>
    </Modal>
  </Container>
}
export default ShareWidget
